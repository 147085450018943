// Setup Honeybadger
import Honeybadger from '@honeybadger-io/js';
import { datadogRum } from "@datadog/browser-rum";


console.log('application.js');

if (process.env.HONEYBADGER_API_KEY) {
  console.log(`Launching ${process.env.RAILS_ENV} Honeybadger Vue`);
  Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.RAILS_ENV,
  });
}

const { id, dataset } = document.body;
const { flashNotice, flashError } = dataset;


// DataDog configuration
if (process.env.RAILS_ENV !== 'development') {
  datadogRum.init({
    // These get exposed on frontend anyway, so there's no point in trying to obfuscate the id
    applicationId: '52dca5e2-d64c-4cce-b979-481950575cf7',
    clientToken: 'pub2b9dd23d09146f87bcb535fe01bcb515',
    site: 'datadoghq.com',
    service: 'rotunda-dealer-assessment',
    env: process.env.RAILS_ENV,
    version: process.env.DD_VERSION,
    allowedTracingUrls: ["https://qa.rotundareap.com", "https://staging.rotundareap.com", "https://rotundareap.com"],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10, // out of 100% sessions, 20% will be record
    trackUserInteractions: (process.env.RAILS_ENV === 'production'),
    trackResources: (process.env.RAILS_ENV === 'production'),
    trackLongTasks: (process.env.RAILS_ENV === 'production'),
    defaultPrivacyLevel: 'mask-user-input',
  });
}






console.log('ID', id)
if (id === 'home-index') {
  import('../js/views/admin/index');
} else if (id === 'equipment_assessments-index') {
  import('../js/views/assessment/index');
} else if (id === 'home-dashboard') {
  import('../js/views/dashboard/index');
} else if (id === 'devise-sessions-new') {
  import('../js/views/devise/sessions/new');
} else if (id === 'home-mobile') {
  import('../js/views/mobile/index');
} else if (id === 'reports-show') {
  import('../js/views/reports/index');
}

// Setup header
const profileMenuLink = document.getElementById('profile-menu-link');
const profileDropdown = document.getElementById('profile-dropdown');

if (profileMenuLink && profileDropdown) {
  profileMenuLink.addEventListener('click', (e) => {
    // e.preventDefault();
    profileDropdown.classList.toggle('show');
  });
}
